<!-- 我的钱包页面 -->
<template>
  <div class="pageBg">
    <HeadGoBackVue iCustomizdde="true" :icon="true">
      <template v-slot:left-icon>
        <van-icon name="arrow-left" @click="$fun.routerToPage('/user/index')" />
      </template>
      <template #default>
        <span> {{ $fanyi("我的钱包") }} </span>
        <div
          class="checkDetail"
          @click="$fun.routerToPage('/user/churujinList')"
        >
          {{ $fanyi("明细") }}
        </div>
      </template>
    </HeadGoBackVue>
    <div class="purseBox">
      <!-- 账户余额 -->
      <h4>{{ $fanyi("余额") }}</h4>
      <div class="balanceBox">
        <span>
          <span>{{
            $fun.JPYNumSegmentation($store.state.userInfo.balance)
          }}</span>
          <span class="danwei">{{ $fanyi("円") }}</span>
        </span>
        <!-- 2023/7/12 h5不需要能够提现 -->
        <!-- <button @click="$fun.routerToPage('/user/withdrawDeposit')">
          {{ $fanyi("提现") }}
        </button> -->
      </div>
      <!-- 其他余额 -->
      <div class="otherBalances">
        <div
          class="otherBalancesOpt"
          v-if="$store.state.userInfo.balance_freezing"
        >
          <p class="oBTitle">{{ $fanyi("冻结") }}</p>
          <p class="oBCon">
            {{
              $fun.JPYNumSegmentation($store.state.userInfo.balance_freezing)
            }}
            <span class="danwei">{{ $fanyi("円") }}</span>
          </p>
        </div>
        <div class="otherBalancesOpt">
          <p class="oBTitle">{{ $fanyi("入金待确认金额") }}</p>
          <p class="oBCon">
            {{
              $fun.JPYNumSegmentation(
                $store.state.userInfo.balance_recharge_audit
              )
            }}
            <span class="danwei">{{ $fanyi("円") }}</span>
          </p>
        </div>
      </div>
      <!-- 按钮 -->
      <button
        class="topUpButton linkBtn"
        @click="$fun.routerToPage('/user/recharge')"
      >
        {{ $fanyi("充值") }}
      </button>
      <!-- 2023/6/15 h5不做提现记录了,但是可以提现 -->
      <!-- <button class="payoutRecordButton linkBtn">
        {{ $fanyi("提现记录") }}
      </button> -->
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import HeadGoBackVue from "@/components/headGoBack";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.pageBg {
  background: #f6f6f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .checkDetail {
    position: absolute;
    right: 36px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  .purseBox {
    flex: 1;
    margin: 18px 30px 0;
    border-radius: 6px;
    background-color: white;

    padding: 41px 30px;

    h4 {
      font-size: 28px;
      font-weight: 600;
      line-height: 1;
    }

    .balanceBox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 42px;
      margin-bottom: 42px;
      border-bottom: solid 1px #dfdfdf;

      span {
        margin-top: 32px;
        font-size: 56px;
        font-weight: 600;
        color: #b4272b;
        line-height: 1;
      }

      .danwei {
        font-size: 24px;
        margin-left: 8px;
      }

      button {
        min-width: 150px;
        height: 60px;
        padding: 0 20px;
        background: #b4272b;
        border-radius: 6px;
        font-size: 24px;
        line-height: 1;
        color: white;
      }
    }

    .otherBalances {
      display: flex;

      .otherBalancesOpt {
        flex: 1;

        .oBTitle {
          font-size: 28px;
          line-height: 1;
          margin-bottom: 19px;
          color: #999999;
        }

        .oBCon {
          font-size: 36px;
          line-height: 1;
          font-weight: 600;

          .danwei {
            font-size: 24px;
            font-weight: 400;
          }
        }
      }
    }

    .linkBtn {
      width: 570px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      margin: 30px;
      font-size: 28px;
    }

    .linkBtn.topUpButton {
      margin-top: 161px;
      color: white;
    }

    .linkBtn.payoutRecordButton {
      margin-top: 0;
      background-color: white;
      border: 1px solid #dfdfdf;
    }
  }
}
</style>
